// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery3
//= require popper
//= require rails-ujs

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@fortawesome/fontawesome-free/js/all"
import "bootstrap"
import "stylesheets/application"
//import "flash_messages"

Rails.start()
ActiveStorage.start()

$(document).ready(function() {
  // Modal exchange integration
  $(".universal-copy-and-paste").on('click', function(event) {
    event.preventDefault();
    $(this).hide();

    var currentElement = $(this);
    var orgText = currentElement.html();
    var textToCopy = currentElement.prev().text();

    if(textToCopy !== '' && textToCopy !== undefined) {
      navigator.clipboard.writeText(textToCopy).then(
        function() {
          // Don't let the copy when Copied! notification is seen
          currentElement.next('.copied-success').removeClass('d-none').show();
        },
        function() {
          /* clipboard write failed */
          currentElement.next('.copied-success').removeClass('d-none').html("<span class='text-danger'>Copying failed! Copy and paste manually.</span>").show();
        }
      )
    }

    setTimeout(function() {
      currentElement.next('.copied-success').addClass('d-none').hide();
      currentElement.show();
    }, 1000);
  });
  if($('#flash_notice').text() === 'You are now logged in') {
    setTimeout(function() {
      $('.alert-success .btn-close').trigger('click');
    }, 2000);
  }

  $("#buys-paginator > nav > ul > li").click(function() {
    document.getElementById("repeat-buys-header").scrollIntoView({behavior: "smooth"});
  });
  $("#withdraw-paginator > nav > ul > li").click(function() {
    document.getElementById("repeat-withdraw-header").scrollIntoView({behavior: "smooth"});
  });

  var currentPage = window.location.href;
  if(currentPage.indexOf("/users") > -1) {
    if($(".alert-danger").length > 0) {
      document.querySelector('.alert-danger').scrollIntoView({behavior: "smooth"});
    }
  }

  // Initiate BS tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Enable popovers globally
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl);
  });

  // Hover on 2 dashboard blocks (home page)
  // $(".custom-hover-box").hover(
  //   function() { // hover in
  //     $(this).find("div.text-success").attr('style', 'opacity: 1 !important;');
  //     $(this).find("div.text-muted-success").attr('style', 'opacity: 1 !important;');
  //   }, function() { // hover out
  //     $(this).find("div.text-success").attr('style', 'opacity: .8 !important;');
  //     $(this).find("div.text-muted-success").attr('style', 'opacity: .8 !important;');
  //   }
  // );

  // upgrade
  $(".upgrade-profile").hover(
    function() { // hover in
      $(this).find("div.dropdown-item-icon").attr('style', 'color: #fff !important;');
      $(this).find("strong.text-success").attr('style', 'color: #fff !important;');
    }, function() { // hover out
      $(this).find("div.dropdown-item-icon").attr('style', 'color: #00ac69 !important;');
      $(this).find("strong.text-success").attr('style', 'color: #00ac69 !important;');
    }
  );

  // Activate Bootstrap scrollspy for the sticky nav component
  const stickyNav = document.body.querySelector('#stickyNav');
  if (stickyNav) {
      new bootstrap.ScrollSpy(document.body, {
          target: '#stickyNav',
          offset: 82,
      });
  }

  // Save user browser time zone after sign up if different from DB default
  if($('#flash_notice').length > 0) {
      if($('#flash_notice').html().includes("Welcome to ZenDCA! You have successfully registered for a free account.")) {
        // Show welcome tutorial
        $("#welcomeModal").modal('show');

        $('#welcomeModal').on('hidden.bs.modal', function (e) {
          // Hide content in case video is still playing (so it's not playing on bg when modal closes)
          $('#welcomeModal').html('');
        });

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // e.g. America/New_York

        if(timezone.length > 0 && timezone !== "America/New_York") {
          $.post({
              url: "/users/update_tz_from_browser_after_signup",
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: JSON.stringify({browser_time_zone: timezone}),
              contentType: 'application/json; charset=utf-8'
          });
        }

        // Send event to google ads
        gtag('event', 'conversion', {'send_to': 'AW-825835763/enHgCNjV_PMZEPOB5YkD'});
      }
  }

  // Mobile setting buttons
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // display mobile only elements
      $(".mobile-only-setting").show();
      $(".mobile-only-crypto-prices").show();
  } else {
      $(".mobile-only-setting").hide();
      $(".mobile-only-crypto-prices").hide();
      // Redirect to dashboard if someone tries to visit on desktop
      if($(location).attr("pathname") === "/mobile-markets") {
        $(location).attr('href', '/');
      }
  }
});
